import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const TermsPopup = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="terms-dialog-title">
      <DialogTitle id="terms-dialog-title">Términos y condiciones de uso de la red WIFI de la Ivtec Barlovento.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            Al acceder a la red inalámbrica, usted reconoce que es mayor de edad, ha leído, comprendido y acepta vincularse a este acuerdo.
          </Typography>
          <Typography variant="body1">
            Usted acepta no utilizar la red inalámbrica para ningún propósito que sea ilegal y asume toda la responsabilidad de sus actos.
          </Typography>
          <Typography variant="body1">
            La red inalámbrica se proporciona "tal cual" sin garantías de ningún tipo, ya sea expresa o implícita.
          </Typography>

          {/* Espacio entre párrafos */}
          <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
            Al acceder y utilizar la red WI-FI de IVTEC BARLOVENTO CA J-501926581, declaras que has leído, entendido y aceptas los términos y condiciones para su utilización. Si no estás de acuerdo con esta norma, no debes acceder a este servicio.
          </Typography>
          <Typography variant="body1">
            La red WI-FI está destinada únicamente para el uso exclusivo de los usuarios que se encuentren en las instalaciones o zonas de cobertura wifi de la IVTEC BARLOVENTO CA J-501926581
          </Typography>

           {/* Espacio entre párrafos */}
           <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
            Aceptas y reconoces que hay riesgos potenciales a través de un servicio WI-FI. Debe tener cuidado al transmitir datos como: número de tarjeta de crédito, contraseñas u otra información personal sensible a través de redes WI-FI. IVTEC BARLOVENTO CA J-501926581 no puede y no garantiza la privacidad y seguridad de sus datos y de las comunicaciones al utilizar este servicio.          
          </Typography>

           {/* Espacio entre párrafos */}
           <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
            IVTEC BARLOVENTO CA J-501926581, no garantiza el nivel de funcionamiento de la red WI-FI. El servicio puede no estar disponible o ser limitado en cualquier momento y por cualquier motivo, incluyendo emergencias, sobrecarga de conexiones, fallo del enlace, problemas en equipos de red, interferencias o fuerza de la señal. 
          </Typography>

           {/* Espacio entre párrafos */}
           <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
            IVTEC BARLOVENTO CA J-501926581 no se responsabiliza por datos, mensajes o páginas perdidas, no guardadas o retrasos por interrupciones o problemas de rendimiento con el servicio.
          </Typography>

           {/* Espacio entre párrafos */}
           <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
            IVTEC BARLOVENTO CA J-501926581 puede establecer límites de uso, suspender el servicio o bloquear ciertos comportamientos, acceso a ciertos servicios o dominios para proteger la red de fraudes o actividades que atenten contra las leyes nacionales o internacionales.
          </Typography>

           {/* Espacio entre párrafos */}
           <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
            Tanto IVTEC BARLOVENTO CA J-501926581 como los administradores de este servicio pondrán en conocimiento de las autoridades competentes, de manera inmediata, cualquier uso ilegal o fraudulento de la red.
          </Typography>

           {/* Espacio entre párrafos */}
           <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
            No se podrá utilizar la red WIFI con los siguientes fines:

            Transmisión de contenido fraudulento, difamatorio, obsceno, ofensivo o de vandalismo, insultante o acosador, sea cual sea su formato.

            Interceptar, recopilar o almacenar datos sobre terceros sin su conocimiento o consentimiento.

            Escanear o probar la vulnerabilidad de equipos, sistemas o segmentos de esta red. 

            Este acto será considerado un ataque informático y será puesto en conocimiento de las autoridades legales competentes.

            Enviar mensajes no solicitados (spam), virus, o realizar ataques informáticos internos o externos a la red de IVTEC BARLOVENTO CA J-501926581.

            Obtener acceso no autorizado a equipos, sistemas o programas tanto al interior de la red de  Términos y condiciones de uso de la red WIFI de la IVTEC BARLOVENTO CA J-501926581 como fuera de ella.

            Obtener, manipular y compartir cualquier archivo sin tener los derechos de propiedad intelectual.

            Transmitir, copiar y/o descargar cualquier material que viole cualquier ley.  

            Esto incluye entre otros: material con derecho de autor, pornografía infantil, material amenazante u obsceno, o material protegido por secreto comercial o patentes.

            Dañar equipos, sistemas informáticos o redes y/o perturbar el normal funcionamiento de esta u otras redes. Ser utilizada para crear y/o la infectar con virus informático o malware en la red.

            Fines de lucro y actividades comerciales de cualquier tipo ya sean legales o ilegales.
            Cualquier otro uso malicioso.
          </Typography>

          {/* Espacio entre párrafos */}
          <div style={{ marginBottom: '10px' }} />
          <DialogTitle id="terms-dialog-title">Política de privacidad de la red WIFI de IVTEC BARLOVENTO CA J-501926581.</DialogTitle>

          {/* Espacio entre párrafos */}
          <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
          Finalidad:
          Los datos se tratan para la prestación del servicio de administración y mantenimiento de la red wifi de IVTEC BARLOVENTO CA J-501926581. Con la finalidad de ofrecerle el mejor servicio, y con el objeto de facilitar el uso, se analizan datos como el tráfico, el uso y rendimiento de la red wifi, los fallos que se producen y otros de similar naturaleza. 

          A estos efectos, se utiliza la información estadística suministrada por la tecnología subyacente con el único fin de mejorar el servicio, dotarlo de seguridad y detectar posibles usos ilegales, fraudulentos o contrarios a los términos de uso de la red.

          No se tiene acceso ni se almacena información sobre los usuarios, los sitios que visitan ni los contenidos de sus conversaciones de cualquier tipo con otros usuarios de internet. 

          Esto no implica que, ante requerimiento judicial, se pueda almacenar este tipo de información, referida siempre a usuarios concretos de la red.

          Los datos de carácter personal que se pudieran recabar directamente del interesado, y previo su consentimiento, serán tratados de forma confidencial y quedarán incorporados a la correspondiente actividad de tratamiento cuyo titular es IVTEC BARLOVENTO CA J-501926581.

          Los datos de carácter personal proporcionados se conservarán durante el tiempo necesario para cumplir con la finalidad para la que se recaban, siendo posteriormente destruidos. 

          No obstante, el Encargado del Tratamiento de los datos puede conservar una copia, con los datos debidamente boqueados, mientras puedan derivarse responsabilidades de la ejecución de la prestación.
          </Typography>

          {/* Espacio entre párrafos */}
          <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
            Destinatarios:
            Los datos de carácter personal no podrán ser comunicados a terceros, salvo obligación legal.
          </Typography>

          {/* Espacio entre párrafos */}
          <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
            Derechos
            Los usuarios de la red wifi pueden ejercitar, si lo desean, los derechos de acceso y rectificación de datos, así como solicitar que se limite el tratamiento de sus datos personales u oponerse al mismo, dentro de lo dispuesto en la normativa vigente, dirigiendo una solicitud ivtecbarlovento.com soporte@ivtecbarlovento.com
          </Typography>

           {/* Espacio entre párrafos */}
           <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
            Modificaciones en los Términos y Condiciones:
            IVTEC BARLOVENTO CA J-501926581 se reserva el derecho de modificar estos términos y condiciones en cualquier momento, siendo responsabilidad del usuario revisar periódicamente dichas modificaciones.
          </Typography>

          {/* Espacio entre párrafos */}
          <div style={{ marginBottom: '10px' }} />

          <Typography variant="body1">
            Legislación Aplicable:
            Estos términos y condiciones se rigen por las leyes de Venezuela. Cualquier controversia derivada del uso de la zona wifi será sometida a la jurisdicción de los tribunales competentes en Venezuela.

            Al aceptar estos términos y condiciones, el usuario manifiesta su conformidad con el tratamiento de sus datos personales por parte de IVTEC BARLOVENTO CA J-501926581 para los fines mencionados anteriormente.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsPopup;
