import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { plans } from '../Config/PlanConfig';
import { payments } from '../Config/PaymentConfig';
import Logo from '../logo.PNG';

const API_BASE_URL = 'https://cajpm.com/api';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
  },
  logo: {
    width: 120,
    height: 'auto',
    marginBottom: theme.spacing(2), // Ajusta el margen inferior según sea necesario
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  selectContainer: {
    marginBottom: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[3],
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
  closeButton: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  paidText: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
}));

const Admin = () => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [clientes, setClientes] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPlanesModal, setOpenPlanesModal] = useState(false);
  const [openPagoModal, setOpenPagoModal] = useState(false);
  const [plan, setPlan] = useState('');
  const [metodoPago, setMetodoPago] = useState('');
  const [observacion, setObservacion] = useState('');
  const [observacionPago, setObservacionPago] = useState('');
  const [planesCliente, setPlanesCliente] = useState([]);
  const [planSeleccionado, setPlanSeleccionado] = useState(null);
  const [user, setUser] = useState(null);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      window.location.href = '/login';
    }
  }, []);

  const handleCerrarSesion = () => {
    localStorage.removeItem('user');
    window.location.href = '/login'; // Ajusta la ruta según corresponda
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAgregarPlan = (cliente) => {
    setClienteSeleccionado(cliente);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const instance = axios.create({
        baseURL: API_BASE_URL,
      });
      const response = await instance.get(`/selectCustomers.php?q=${searchQuery}`);
      setClientes(response.data);
      setError('');
    } catch (error) {
      setError('Error al buscar clientes');
      setClientes([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGuardarPlan = async () => {
    if (!clienteSeleccionado) return;
    try {
      const instance = axios.create({
        baseURL: API_BASE_URL,
      });
      const response = await instance.post('/insertPlan.php', {
        tipoId: clienteSeleccionado.TipoId,
        cedula: clienteSeleccionado.Cedula,
        nombre: clienteSeleccionado.Nombre,
        telefono: clienteSeleccionado.Telefono,
        ciudad: clienteSeleccionado.Ciudad,
        sitio: clienteSeleccionado.Sitio,
        plan: plan,
        metodopago: metodoPago,
        observacion: observacion,
        user: user.UserName
      });
      if (response.status === 200) {
        alert('Plan agregado de forma Exitosa.');
        setOpenModal(false);
        setPlan('')
        setMetodoPago('')
        setObservacion('')
      }
    } catch (error) {
      console.error('Error al agregar plan:', error);
    }
  };

  const handleVerPlanes = async (cedula) => {
    try {
      const instance = axios.create({
        baseURL: API_BASE_URL,
      });
      const response = await instance.get(`/selectPlans.php?cedula=${cedula}`);
      setPlanesCliente(response.data);
      setError('');
      setOpenPlanesModal(true);
    } catch (error) {
      setError('Error al obtener los planes del cliente');
      setPlanesCliente([]);
    }
  };

  const handleGuardarPago = async () => {
    if (planSeleccionado) {
      try {
        const instance = axios.create({
          baseURL: API_BASE_URL,
        });
        const response = await instance.put('/insertPayment.php', {
          planId: planSeleccionado.Id,
          metodoPago: metodoPago,
          observacionPago: observacionPago,
          user: user.UserName,
        });
        if (response.status === 200) {
          setOpenPagoModal(false);
          alert('Plan pagado de forma Exitosa.');
          handleVerPlanes(planSeleccionado.Cedula);
        }
      } catch (error) {
        console.error('Error al agregar pago:', error);
      }
    }
  };

  const handleClosePlanesModal = () => {
    setOpenPlanesModal(false);
  };

  const handleAgregarPago = (plan) => {
    setPlanSeleccionado(plan);
    setOpenPagoModal(true);
  };

  const handleClosePagoModal = () => {
    setOpenPagoModal(false);
    setPlanSeleccionado(null);
  };
  

  return (
    <div>
      {/* Logo */}
      <img src={Logo} alt="Logo" className={classes.logo} />

      <div className={classes.buttonContainer}>
        <Button variant="contained" color="info" onClick={handleCerrarSesion}>
          Cerrar Session
        </Button>
      </div>

      <Typography variant="h5">Consultar Clientes</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="searchQuery"
          label="Buscar por cédula, nombre o apellido"
          name="searchQuery"
          value={searchQuery}
          onChange={handleChange}
        />
        <Button type="submit" variant="contained" color="primary">
          Buscar
        </Button>
      </form>
      {isLoading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {clientes.length > 0 && (
        <TableContainer component={Paper}>
          <Table aria-label="Clientes">
            <TableHead>
              <TableRow>
                <TableCell>Cédula</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Teléfono</TableCell>
                <TableCell>Ciudad</TableCell>
                <TableCell>Fecha de Registro</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientes.map((cliente) => (
                <TableRow key={cliente.id}>
                  <TableCell>{cliente.Cedula}</TableCell>
                  <TableCell>{cliente.Nombre}</TableCell>
                  <TableCell>{cliente.Telefono}</TableCell>
                  <TableCell>{cliente.Ciudad}</TableCell>
                  <TableCell>{cliente.FechaRegistro}</TableCell>
                  <TableCell>
                    <div className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAgregarPlan(cliente)}
                    >
                        Agregar plan
                      </Button>
                      <Button variant="contained" color="primary" onClick={() => handleVerPlanes(cliente.Cedula)}>
                        Ver Planes
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Modal open={openModal} onClose={handleCloseModal} className={classes.modal}>
        <div className={classes.paper}>
          <Typography variant="h6">Agregar Plan</Typography>
          <div className={classes.selectContainer}>
            <Select
                label="Plan"
                value={plan}
                onChange={(e) => setPlan(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
              >
                {plans.map((plan, index) => (
                  <MenuItem key={index} value={plan.name + ' - ' + plan.price}>{plan.name + ' - ' + plan.price}</MenuItem>
                ))}
              </Select>
          </div>
          <div className={classes.selectContainer}>
            <Select
              label="Método de Pago"
              value={metodoPago}
              onChange={(e) => setMetodoPago(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            >
                {payments.map((payment, index) => (
                  <MenuItem key={index} value={payment.name}>{payment.name}</MenuItem>
                ))}
            </Select>
          </div>
          <div className={classes.selectContainer}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="observacion"
              label="Observación"
              name="observacion"
              value={observacion}
              onChange={(e) => setObservacion(e.target.value)}
            />
          </div>
          <Button variant="contained" color="primary" onClick={handleGuardarPlan}>
            Guardar
          </Button>
          <Button variant="contained" onClick={handleCloseModal}>
            Cancelar
          </Button>
        </div>
      </Modal>

      <Modal open={openPlanesModal} onClose={handleClosePlanesModal} className={classes.modal}>
        <div className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Planes de Cliente
          </Typography>
          {planesCliente.length > 0 ? (
            planesCliente.map((plan, index) => (
              <Card key={index} className={classes.card}>
                <CardContent>
                  <Typography variant="subtitle1">{plan.Plan}</Typography>
                  {plan.Plan.includes('GRATIS') ? (
                    <Typography variant="body2" className={classes.paidText}>
                      GRATIS
                    </Typography>
                  ) : plan.Status === 'NoPago' ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleAgregarPago(plan)}
                      className={classes.addButton}
                    >
                      Pagar
                    </Button>
                  ) : (
                    <Typography variant="body2" className={classes.paidText}>
                      PAGADO
                    </Typography>
                  )}
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>No hay planes disponibles</Typography>
          )}
          <Button variant="contained" onClick={handleClosePlanesModal} className={classes.closeButton}>
            Cerrar
          </Button>
        </div>
      </Modal>

      <Modal open={openPagoModal} onClose={handleClosePagoModal} className={classes.modal}>
        <div className={classes.paper}>
          <Typography variant="h6">Pagar Plan</Typography>
          <div className={classes.selectContainer}>
            <Select
              label="Método de Pago"
              value={metodoPago}
              onChange={(e) => setMetodoPago(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            >
              {payments.map((payment, index) => (
                <MenuItem key={index} value={payment.name}>{payment.name}</MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.selectContainer}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="observacionPago"
              label="Observación"
              name="observacionPago"
              value={observacionPago}
              onChange={(e) => setObservacionPago(e.target.value)}
            />
          </div>
          <Button variant="contained" color="primary" onClick={handleGuardarPago}>
            Pagar
          </Button>
          <Button variant="contained" onClick={handleClosePagoModal}>
            Cancelar
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Admin;
