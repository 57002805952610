import React, { useState } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TermsPopup from '../TermsPopup/TermsPopup';
import { plans } from '../Config/PlanConfig';
import Logo from '../logo.PNG';
import Background from '../fondo.jpg';
import Swal from 'sweetalert2';

const API_BASE_URL = 'https://cajpm.com/api';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  paper: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    boxShadow: theme.shadows[1],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    maxWidth: 400,
    width: '100%',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: 120,
    height: 'auto',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  planContainer: {
    padding: theme.spacing(2),
    border: '1px solid #ccc',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'box-shadow 0.3s ease',
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
  },
  planText: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    tipoid: 'V',
    cedula: '',
    nombre: '',
    telefono: '',
    ciudad: '',
    sitio: '',
    plan: '',
  });
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openTermsPopup, setOpenTermsPopup] = useState(false);
  const [isSitioVisible, setIsSitioVisible] = useState(false);
  const [isFormRegistroVisible, setIsFormRegistroVisible] = useState(true);
  const [isFormPlanVisible, setIsFormPlanVisible] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'allowExtraEmails') {
      setFormData({
        ...formData,
        allowExtraEmails: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleCedulaChange = (e) => {
    const { name, value } = e.target;
    if (/^\d*$/.test(value)) { // Permitir solo números
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const handleTermsPopupOpen = () => {
    setOpenTermsPopup(true);
  };

  const handleTermsPopupClose = () => {
    setOpenTermsPopup(false);
  };

  const handleCiudadChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setIsSitioVisible(value === 'Barlovento');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setErrorMessage('Debe aceptar los términos y condiciones.');
      return;
    }
    try {
      const instance = axios.create({
        baseURL: API_BASE_URL,
      });
      const response = await instance.post('/insertCustomer.php', formData);
      if (response.status === 200) {
        setErrorMessage('');
        setResponseMessage('Enviado con éxito');
        setUserId(response.data.id);
        setIsFormRegistroVisible(false);
        setIsFormPlanVisible(true);
      }
    } catch (error) {
      if (error.response && (error.response.status === 400 || error.response.status === 403)) {
        setResponseMessage('');
        setErrorMessage('Error: ' + error.response.data.error);
      } else {
        setErrorMessage('Error: No se pudo enviar la solicitud');
      }
    }
  };


  const handlePlanSelect = async (plan) => {
    try {
      let cedula = formData.cedula;
      const instance = axios.create({
        baseURL: API_BASE_URL,
      });
      const response = await instance.put('/updatePlan.php', {
        userId,
        plan,
        cedula,
      });
      if (response.status === 200) {
        Swal.fire({
          title: 'Registro Exitoso',
          text: `Seleccionaste ${plan}, ahora busca a los chicos de Ivtec para realizar tu pago o retirar el ticket si seleccionaste un plan gratis`,
          icon: 'success',
        }).then(() => {
          window.location.href = 'https://www.instagram.com/ivtec_conecta?igshid=MW9lYmJlbzA4NWtsdw==';
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        Swal.fire({
          title: 'Error',
          text: error.response.data.message || 'No se pudo seleccionar el plan',
          icon: 'error',
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'No se pudo seleccionar el plan',
          icon: 'error',
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      {isFormRegistroVisible && (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <div className={classes.logoContainer}>
              <img src={Logo} alt="Logo" className={classes.logo} />
            </div>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Registrarse
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    variant="outlined"
                    required
                    fullWidth
                    name="tipoid"
                    label="Tipo"
                    id="tipoid"
                    value={formData.tipoid}
                    onChange={handleChange}
                  >
                    <MenuItem value="V">V</MenuItem>
                    <MenuItem value="E">E</MenuItem>
                    <MenuItem value="J">J</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="cedula"
                    label="Cedula"
                    id="cedula"
                    autoComplete="cedula"
                    value={formData.cedula}
                    onChange={handleCedulaChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="fname"
                    name="nombre"
                    variant="outlined"
                    required
                    fullWidth
                    id="nombre"
                    label="Nombre y Apellido"
                    autoFocus
                    value={formData.nombre}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="telefono"
                    label="Telefono"
                    id="telefono"
                    autoComplete="telefono"
                    value={formData.telefono}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    variant="outlined"
                    required
                    fullWidth
                    name="ciudad"
                    label="Lugar de Residencia"
                    id="ciudad"
                    value={formData.ciudad}
                    onChange={handleCiudadChange}
                  >
                    <MenuItem value="Caracas">Caracas</MenuItem>
                    <MenuItem value="La Guaira">La Guaira</MenuItem>
                    <MenuItem value="Barlovento">Barlovento</MenuItem>
                    <MenuItem value="Otros">Otros</MenuItem>
                  </TextField>
                </Grid>
                {isSitioVisible && (
                  <Grid item xs={12}>
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      name="sitio"
                      label="Sitio"
                      id="sitio"
                      value={formData.sitio}
                      onChange={handleChange}
                    >
                      <MenuItem value="Higuerote">Higuerote</MenuItem>
                      <MenuItem value="Tacarigua">Tacarigua</MenuItem>
                      <MenuItem value="Carenero">Carenero</MenuItem>
                      <MenuItem value="Chirimena">Chirimena</MenuItem>
                      <MenuItem value="Curiepe">Curiepe</MenuItem>
                      <MenuItem value="Sotillo">Sotillo</MenuItem>
                      <MenuItem value="Otros">Otros</MenuItem>
                    </TextField>
                  </Grid>
                )}
                <Grid item xs={12}>
                <input
                      type="checkbox"
                      name="termsAccepted"
                      checked={termsAccepted}
                      onChange={handleTermsChange}
                />
                <label onClick={handleTermsPopupOpen} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}>
                    Acepto los términos y condiciones
                </label>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!termsAccepted} // Deshabilita el botón si los términos no están aceptados
              >
                Registrarse
              </Button>
            </form>
            {responseMessage && (
              <Typography variant="body1" color="primary" align="center">
                {responseMessage}
              </Typography>
            )}
            {errorMessage && (
              <Typography variant="body1" color="error" align="center">
                {errorMessage}
              </Typography>
            )}
          </div>
        </Container>
      )}

      {isFormPlanVisible && (
        <Container component="div" maxWidth="xs">
          <div className={classes.paper}>
            <div className={classes.logoContainer}>
              <img src={Logo} alt="Logo" className={classes.logo} />
            </div>
            <Typography component="h1" variant="h5">
              Planes
            </Typography>
            <Grid container spacing={2}>
              {plans.map((plan) => (
                <Grid item key={plan.id} xs={12}>
                  <div className={classes.planContainer}>
                    <div className={classes.planText}>
                    <Typography variant="h4">{plan.time}</Typography>
                      <Typography variant="h6">{plan.name}</Typography>
                    </div>
                    <Button variant="contained" color="primary" onClick={() => handlePlanSelect(plan.name + ' - ' + plan.price)}>
                      {plan.price}
                    </Button>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
      )}
      <TermsPopup open={openTermsPopup} handleClose={handleTermsPopupClose}/>
    </div>
  );
}